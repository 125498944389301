import noty from '@apps/Notifications/lang/kk'
import support from '@apps/Support/lang/kk'
import profiler from './mobules/profiler/kk'
import upload from './mobules/upload/kk'
import chat from '@apps/vue2ChatComponent/lang/kk'
import calendar from '@apps/Calendar/lang/kk'
import auth from './mobules/auth/kk'
import group from '@apps/vue2GroupsAndProjectsComponent/lang/kk'
import table from './mobules/table/kk'
import dashboard from '@apps/Dashboard/lang/kk'
import inquiries from '@apps/Inquiries/lang/kk'
import task from '@apps/vue2TaskComponent/lang/kk'
import files from './mobules/files/kk'
import history from '@apps/History/lang/kk'
import comment from '@apps/vue2CommentsComponent/lang/kk'
import meeting from '@apps/vue2MeetingComponent/lang/kk'
import invest from '@apps/InvestProject/lang/kk'

export default {
    "ru": "Орысша",
    "en": "Ағылшынша",
    "kk": "Қазақша",
    "profile": "Профиль",
    "show_all": "Барлығын көрсету",
    "select_filter": "Сүзгіні таңдау",
    "filters": "Сүзгілер",
    "find": "Іздеу",
    "reset": "Қалпына келтіру",
    "close": "Жабу",
    "yes": "Иә",
    "no": "Жоқ",
    "no_data": "Деректер жоқ",
    "enable": "Қосу",
    "exclude": "Шығарып тастау",
    "clear": "Тазалау",
    "name": "Атауы",
    "new": "Жаңа",
    "in_work": "Жұмыста",
    "on_pause": "Үзілісте",
    "on_check": "Тексеруде",
    "on_rework": "Қайта өңдеуде",
    "completed": "Аяқталды",
    "to_work": "Орындауды бастау",
    "to_pause": "Үзіліс жасау",
    "to_check": "Тексеруге жіберу",
    "to_rework": "Қайта өңдеу",
    "to_completed": "Аяқтау",
    "to_new": "Жаңа",
    "dead_line": "Мерзімнің аяқталуы",
    "priority": "Басымдық",
    "tasks.TaskModel": "тапсырманы",
    "enter_your_message": "Хабарламаңызды енгізіңіз",
    "reply_to_message": "Хабарламаға жауап беру",
    "systemic": "Жүйелік",
    "uncover": "Ашу",
    "collapse": "Жабу",
    "comments": "Пікірлер",
    "answers": "Жауаптар",
    "hide_replies": "Жауаптарды жасыру",
    "load_more_answers": "Тағы жауаптар жүктеу",
    "answer_for": "жауап беру",
    "load_more": "Тағы жүктеу",
    "enter_your_comment": "Пікіріңізді енгізіңіз",
    "comment2": "Пікір",
    "comment_added": "Пікір қосылды",
    "comment_delete": "Пікір жойылды",
    "to_answer": "Жауап беру",
    "share_to_chat": "Чатпен бөлісу",
    "edit_task": "Тапсырманы өңдеу",
    "add_task": "Тапсырма қосу",
    "select_date": "Күнді таңдау",
    "select_performer": "Орындаушыны таңдау",
    "auth": "Авторизация",
    "reg_text": "Әлі тіркелгіңіз жоқ па?",
    "reg": "Тіркелу",
    "your_password": "Сіздің құпия сөзіңіз",
    "your_email": "Сіздің E-mail",
    "sing_in": "Кіру",
    "forgot_your_password": "Құпия сөзді ұмыттыңыз ба?",
    "field_required": "Міндетті түрде толтыру қажет",
    "required_sym": "Минимум {sym} символдар",
    "fill_required_fields": "Міндетті өрістерді толтырыңыз",
    "authorisation_error": "Авторизация қатесі",
    "crm.GoodsOrderModel": "Тапсырыс",
    "tasks.TaskSprintModel": "Спринт",
    "files": "Файлдар",
    "remove": "Жою",
    "pick_files": "Тіркелген файлдар",
    "file": "Файл",
    "image_show": "Суретті көру",
    "meetings.PlannedMeetingModel": "Кездесу",
    "processes.FinancialApplicationModel": "Бизнес үдеріс",
    "change_avatar": "Аватарды өзгерту",
    "delete_avatar": "Аватарды жою",
    "success_del_avatar": "Аватар жойылды",
    "success_avatar": "Аватар өзгертілді",
    "max_file_size": "Файлдың максималды өлшемі {size}МБ",
    "file_size": "Файлдың өлшемі",
    "megabytes": "Мб",
    "max_file_h_w": "Суреттің минималды биіктігі мен ені {size}px",
    "update": "Жаңарту",
    "file_modal_title": "Файлмен хабарлама жіберу",
    "file_max_count": "Хабарламада тек {count} файл жібере аласыз",
    "file_and_image": "Файл/Сурет",
    "monochrome_mode": "Монохромды режим",
    "language": "Тіл",
    "desktop_version": "Десктоп нұсқасы",
    "menu_type": "Мәзір түрі",
    "mini_menu": "Кіші мәзір",
    "expanded_menu": "Кеңейтілген мәзір",
    "dynamic_menu": "Динамикалық",
    "header_menu": "Тақырып мәзірі",
    "mobile_version": "Мобильді нұсқа",
    "small": "Кіші",
    "update_theme": "Тақырыпты жаңарту",
    "attached_files": "Қосылған файлдар",
    "file": "Файл",
    "menu_modules": "Мәзір модульдері",
    "menu_sort_alert": "Мәзір элементтерін басты мәзірде сүйреу арқылы да реттеуге болады, ол үшін мәзір элементін басып, қажетті орынға апарыңыз",
    "min_one_module": "Кем дегенде 1 модуль болуы керек",
    "icon": "Белгіше",
    "move": "Жылжыту",
    "visible": "Көрінетін",
    "hidden": "Жасырын",
    "mobile_modules": "Мобильді модульдер",
    "drag_icons_alert": "Модуль белгілерін қажетті орындарға сүйреңіз",
    "menu": "Мәзір",
    "active_modules": "Белсенді модульдер",
    "deactivated_modules": "Өшірілген модульдер",
    "enter_current_password": "Ағымдағы құпиясөзді енгізіңіз",
    "enter_new_password": "Жаңа құпиясөзді енгізіңіз",
    "repeat_new_password": "Жаңа құпиясөзді қайталаңыз",
    "change_password": "Құпиясөзді өзгерту",
    "passwords_mismatch": "Құпиясөздер сәйкес келмейді",
    "password_changed_successfully": "Құпиясөз сәтті өзгертілді",
    "error": "Қате",
    "check_input_data": "Енгізілген деректердің дұрыстығын тексеріңіз",
    "field_required": "Бұл өріс міндетті",
    "required_sym": "Минималды ұзындық: {sym} таңба",
    "last_name": "Тегі",
    "first_name": "Аты",
    "middle_name": "Әкесінің аты",
    "job_title": "Лауазым",
    "contact_phone": "Байланыс телефоны",
    "birthday": "Туған күні",
    "select_date": "Күнді таңдау",
    "is_make_events_in_task_automatically": "Міндеттерде мерзімдер болған жағдайда оқиғаларды автоматты түрде жасау",
    "save": "Сақтау",
    "qr_code_info": "Telegram хабарламаларына қосылу үшін QR-кодты сканерлеңіз немесе сілтеме арқылы өтіңіз",
    "by_link": "сілтеме арқылы",
    "unsubscribe": "Жазылудан бас тарту",
    "recommendations": "Ұсыныстар",
    "fill_last_name": "Тегіңізді толтырыңыз",
    "fill_first_name": "Атыңызды толтырыңыз",
    "fill_middle_name": "Әкесінің атын толтырыңыз",
    "fill_job_title": "Лауазымды толтырыңыз",
    "fill_birthday": "Туған күніңізді толтырыңыз",
    "upload_avatar": "Аватар жүктеңіз",
    "no_data": "Деректер жоқ",
    "reconciliation_act": "Түгендеу актісі",
    "name": "Атауы",
    "currency": "Валюта",
    "reconciliation_act_from": "Түгендеу актісі",
    "error_occurred": "Қате орын алды",
    "not_widget": "Мұндай виджет жоқ.",
    "profile": "Профиль",
    "edit_avatar": "Аватарды өзгерту",
    "success_avatar": "Аватар сәтті жаңартылды!",
    "error": "Қате пайда болды. Қайтадан көріңіз.",
    "max_file_h_w": "Минималды ені мен биіктігі {{size}}px.",
    "install_prompt": "Жылдам кіру үшін қосымшаны орнатасыз ба?",
    "install": "Орнату",
    "my_delivery_points": "Менің жеткізу пункттерім",
    "old_lk": "Ескі ЛК",
    "change_password": "Парольді өзгерту",
    "interface": "Интерфейс",
    "other": "Басқа",
    "clear_cache": "Кэшті тазарту",
    "client_version": "Клиент нұсқасы",
    "ui_components": "UI компоненттері",
    "send_push": "Push жіберу",
    "clear_cache_all": "Барлығын кэштен тазарту",
    "title": "Тақырып",
    "text": "Мәтін",
    "link": "Сілтеме",
    "send": "Жіберу",
    "logout": "Шығу",
    "host": "Хост",
    "build_date": "Құрылыс күні",
    "add_task": "Тапсырма қосу",
    "add_conference": "Конференция қосу",
    "add_event": "Оқиға қосу",
    "add_organization": "Ұйым қосу",
    "widget_not_found": "Ағымдағы беттің виджеты @/views/Dashboard/PageWidgets/<strong>{{ widgetName }}</strong>.vue папкасында табылған жоқ.",
    "page_configuration": "Бет конфигурациясы",
    "filter": "Фильтр",
    "f_select": "Таңдау",
    "f_search": "+ іздеу",
    "f_search_and_filter": "Фильтр + іздеу",
    "d_start": "Бастапқы",
    "d_end": "Аяқталушы",
    "f_input_p": "Мәнді енгізіңіз",
    "f_select_p": "Тізімнен таңдаңыз",
    "f_user_p": "Пайдаланушы атын енгізіңіз",
    "network_error": "Интернет байланысында проблема. Интернетке қосылуды тексеріңіз!",
    "app_update_text": "Қолданбаны жаңарту қажет",
    "app_update": "Жаңарту",
    "previouslySelected": "Бұрын таңдалған",
    "notice_browser_language": "Біз сіздің браузеріңіздің тілі {lang} екенін байқадық.",
    "keep_current_language": "Жоқ, ағымдағы тілді сақтау",
    "change_language": "Иә, тілді {lang} тіліне өзгерту",
    "change_site_language": "Сайттың тілін ыңғайлырақ пайдалану үшін {lang} тіліне өзгерткiңiз келе ме?",
    ...support,
    ...upload,
    ...noty,
    ...profiler,
    ...chat,
    ...calendar,
    ...auth,
    ...group,
    ...table,
    ...dashboard,
    ...inquiries,
    ...task,
    ...files,
    ...history,
    ...comment,
    ...meeting,
    ...invest
}

