export default {
    "auth": {
        "register": "Тіркелу",
        "authorization": "Порталда авторизация",
        "no_account": "Әлі есептік жазбаңыз жоқ па?",
        "your_email": "Сіздің e-mail",
        "enter_email": "E-mail енгізіңіз",
        "your_password": "Сіздің құпия сөзіңіз",
        "enter_password": "Құпия сөзді енгізіңіз",
        "login": "Порталға кіру",
        "forgot_password": "Құпия сөзді ұмыттыңыз ба?",
        "cloud_product_title": "Gos24.kz платформасындағы «КОННЕКТ» бұлттық өнімі",
        "cloud_product_description": "Мемлекеттік мекемелер мен әр түрлі меншік нысандарының тиімді міндеттерін жоспарлауға және басқаруға арналған заманауи және кәсіби жүйе",
        "local_software": "Қазақстан Республикасының ақпараттық қауіпсіздік нормаларына сәйкестігін растайтын отандық бағдарламалық қамтамасыз ету",
        "field_required": "Бұл алаң міндетті түрде толтырылуы тиіс",
        "enter_valid_email": "Дұрыс email енгізіңіз",
        "required_sym": "Максимум {sym} символдар",
        "authorisation_error": "Авторизация қатесі",
        "password_recovery": "Құпия сөзді қалпына келтіру",
        "enter_email_recovery": "Тіркелу кезінде көрсетілген e-mail-ді енгізіңіз, біз сізге құпия сөзді қалпына келтіру сілтемесін жібереміз.",
        "login_portal": "Порталда авторизация",
        "recover_by_email": "E-mail арқылы қалпына келтіру",
        "recover_by_phone": "Телефон нөмірі арқылы қалпына келтіру",
        "password_recovery_meta": "Құпия сөзді қалпына келтіру | Gos24.КОННЕКТ",
        "recover_password": "Құпия сөзді қалпына келтіру",
        "recovery_link_sent": "Құпия сөзді қалпына келтіру сілтемесі сіздің e-mail-ге жіберілді",
        "fill_required_fields": "Міндетті түрде толтырылуы тиіс алаңдарды толтырыңыз",
        "code_verification": "Кодты тексеру",
        "sms_code_placeholder": "SMS-тан код",
        "continue": "Жалғастыру",
        "back": "Артқа",
        "resend_code": "Кодты қайтадан жіберу",
        "resend_code_after": "Кодты қайтадан жіберу:",
        "create_password": "Құпия сөзіңізді ойлап табыңыз",
        "create_strong_password": "Есептік жазбаңызға кіру үшін мықты құпия сөз ойлап табыңыз",
        "create_strong_password_placeholder": "Мықты құпия сөз ойлап табыңыз",
        "repeat_password_placeholder": "Құпия сөзді қайталаңыз",
        "save_password": "Құпия сөзді сақтау",
        "passwords_do_not_match": "Құпия сөздер сәйкес келмейді",
        "password_changed_successfully": "Сіз құпия сөзді сәтті өзгерттіңіз",
        "restore_by_phone": "Телефон нөмірі арқылы қалпына келтіру",
        "your_phone": "Сіздің телефоныңыз",
        "send": "Жіберу",
        "invalid_input": "Толтырылған деректер дұрыс емес",
        "password_recovery_title": "Телефон нөмірі бойынша құпия сөзді қалпына келтіру",
        "registration_title": "Тіркелу",
        "step_info_title": "Ұйым туралы ақпарат",
        "step_info_description": "Ұйымның БИН-ін енгізіңіз, біз автоматты түрде атауы мен иерархиялық құрылымын жүктейміз",
        "step_verification_title": "Верификация",
        "step_verification_description": "Есептік жазбаңызды қорғау үшін жеке тұлғаңызды растаңыз. Көрсетілген электронды поштаға растайтын кодты қамтитын хабарлама жіберіледі. Тіркелуді аяқтау үшін оны төменде енгізіңіз.",
        "step_personal_info_title": "Жеке ақпарат",
        "step_personal_info_description": "Сіздің атыңыз бен тегіңізді көрсетіңіз. Әріптестермен қарым-қатынасты жеңілдету үшін ұйымдағы лауазымыңызды көрсетіңіз.",
        "label_contractor_bin": "Ұйымның БИН-і",
        "label_contractor_name": "Ұйымның атауы",
        "button_back": "Артқа",
        "button_next": "Келесі",
        "placeholder_phone_email": "Сіздің телефоныңыз / E-mail",
        "placeholder_phone": "Сіздің телефоныңыз",
        "placeholder_email": "Сіздің E-mail",
        "error_bin_length": "БИН 12 символдан тұруы тиіс",
        "error_field_required": "Толтыру міндетті",
        "error_bin_not_found": "Көрсетілген БИН базада жоқ",
        "title_create_password": "Құпия сөзді ойлап табыңыз",
        "description_create_password": "Есептік жазбаңызға кіру үшін мықты құпия сөз ойлап табыңыз",
        "label_password": "Құпия сөз",
        "label_confirm_password": "Құпия сөзді қайталаңыз",
        "placeholder_enter_password": "Құпия сөзді енгізіңіз",
        "placeholder_repeat_password": "Құпия сөзді қайталаңыз",
        "title_check_code": "Кодты тексеру",
        "placeholder_code_sms_email": "SMS немесе e-mail-ден код",
        "button_send_code": "Кодты жіберу",
        "label_resend_code": "Кодты қайтадан жіберу:",
        "title_thank_you": "Тіркелгеніңіз үшін рақмет!",
        "message_registration_pending": "Сіздің ұйымыңызды тіркеу өтінімі модерацияда, профиль уақытша бұғатталған. Модерация процедурасынан кейін сіздің профиліңіз ашылады.",
        "button_login": "Кіру",
        "label_last_name": "Тегі",
        "placeholder_last_name": "Тегіңізді енгізіңіз",
        "label_first_name": "Аты",
        "placeholder_first_name": "Атыңызды енгізіңіз",
        "label_middle_name": "Әкесінің аты",
        "placeholder_middle_name": "Әкесінің атын енгізіңіз",
        "label_job_title": "Сіздің лауазымыңыз",
        "placeholder_job_title": "Сіздің лауазымыңызды енгізіңіз",
        "agreement_text": "Келісемін",
        "agreement_link": "жеке деректерді қорғау саясатына",
        "button_register": "Тіркелу",
        "num_error": "Нөмір дұрыс толтырылмаған",
        "label_email": "Email",
        "label_confirm_code": "Растайтын кодты енгізіңіз",
        "placeholder_code": "SMS немесе e-mail-ден код",
        "button_send_confirmation_code": "Көрсетілген e-mail-ге растайтын кодты жіберу",
        "message_code_sent": "Растайтын код e-mail-ге жіберілді {{ email }}",
        "phone_or_mail": "Телефонды / E-mail-ді енгізіңіз",
        "email_reg_next": "Тіркелуді жалғастыру үшін көрсетілген e-mail-ді растауыңыз қажет",
        "email_reg_error": "E-mail дұрыс енгізілмеген",
        "portal_reg_title": "Порталда тіркелу | Gos24.КОН"
    }
}