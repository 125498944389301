export default {
    "dashboard": {
        "activeDashboardName": "{name}",
        "addWidget": "Виджет қосу",
        "editTitle": "Жұмыс үстелін өңдеу",
        "addTitle": "Жұмыс үстелін қосу",
        "name": "Жұмыс үстелінің атауы",
        "save": "Сақтау",
        "addNew": "Жұмыс үстелін қосу",
        "fieldRequired": "Міндетті өріс",
        "requiredSym": "{sym} таңбадан артық емес",
        "updating": "Жаңартылуда",
        "updated": "Жаңартылды",
        "updateError": "Жаңарту қателігі",
        "pageNotFound": "Бет табылмады.",
        "confirmDelete": "Сіз шынымен жұмыс үстелін жоюды қалайсыз ба?",
        "delete": "Жою",
        "cancel": "Бас тарту",
        "updatedSuccessfully": "\"{name}\" жұмыс үстелі жаңартылды",
        "createdSuccessfully": "\"{name}\" жұмыс үстелі құрылды",
        "enter_title": "Атауын енгізіңіз",
        "update_error": "Жаңартудағы қате",
        "dashboard_title": "Жұмыс үстелі",
        "add_widget": "Виджет қосу",
        "edit_dashboard": "Жұмыс үстелін өңдеу",
        "add_dashboard": "Жұмыс үстелін қосу",
        "desktop_name": "Жұмыс үстелінің атауы",
        "confirm_delete": "Сіз шынымен жұмыс үстелін жойғыңыз келе ме?",
        "updated_success": "Жұмыс үстелі \"{name}\" жаңартылды",
        "created_success": "Жұмыс үстелі \"{name}\" құрылды",
        "settings": "Параметрлер",
        "unpin": "Тіркеме",
        "pin": "Тіркеу",
        "hide_mobile": "Ұялы нұсқада жасыру",
        "show_mobile": "Ұялы нұсқада көрсету",
        "rename_widget": "Виджетті қайта атау",
        "move_down": "Төмен жылжыту",
        "move_up": "Жоғары жылжыту",
        "hide_pc": "ПК нұсқасында жасыру",
        "show_pc": "ПК нұсқасында көрсету",
        "widget_settings": "Виджет параметрлері",
        "reset": "Қалпына келтіру",
        "close": "Жабу",
        "task": "Міндет",
        "change": "Өзгерту",
        "select": "Таңдау",
        "order": {
            "create": "Заказ беру",
            "previous_date": "Алдыңғы тапсырыс күні",
            "no_orders": "Тапсырыстар жоқ",
            "completed": "Тапсырыс аяқталды",
            "in_progress": "Тапсырыстар өңделуде"
        },
        "contractor": {
            "not_provided": "Көрсетілмеген"
        },
        "user": {
            "last_seen": "Онлайнда болды {date}",
            "last_seen_ago": "Онлайнда болды {time}"
        },
        "no_widgets": "Виджеттер жоқ",
        "drag_widget": "Виджетті жұмыс үстеліне орналастыру үшін тартыңыз",
        "done": "Дайын",
        "search_placeholder": "Атауын енгізіңіз",
        "all_widgets": "Барлық виджеттер",
        "widget_categories": "Виджеттер санаттары",
        "loading_update": "Жаңарту",
        "full_name": "Толық аты",
        "first_inactive": "Алдымен белсенді емес",
        "first_active": "Алдымен белсенді",
        "activity": "Белсенділік",
        "tasks": "Тапсырмалар",
        "load_error": "Деректерді жүктеу мүмкін болмады",
        "tasks_empty": "Тапсырмалар жоқ",
        "appeals_total": "Кезең бойынша өтінімдер",
        "no_appeals": "Көрсету үшін өтінімдер жоқ",
        "no_organizations": "Көрсету үшін ұйымдар жоқ",
        "higher_level": "Жоғары деңгейге",
        "show_organizations": "Ұйымдарды көрсету",
        "show_appeals": "Өтінімдерді көрсету",
        "number": "Нөмір",
        "date": "Күн",
        "deadlineViolation": "Уақытты бұза отырып қаралды",
        "GOReviewed": "ГО-да қаралды",
        "entraceSource": "Кіріс көздері",
        "appeals": "Тіркелген өтініштер",
        "projectsAbsent": "Жобалар жоқ",
        "meetingsAbsent": "Кездесулер жоқ",
        "teamsAbsent": "Командалар жоқ",
        "eventsAbsent": "Оқиғалар жоқ",
        "addEvent": "Оқиға қосу",
        "allDay": "Күні бойы",
        "appealsPeriod": "Кезең бойынша өтініштер",
        "points": "балл",
        "toList": "Тізімге",
        "appeals": "Өтініштер",
        "backToList": "Тізімге оралу",
        "deadlineViolationAnalytics": "Уақытты бұза отырып қаралған",
        "deadlineViolationTotal": "Уақытты бұза отырып қаралған",
        "GOReviewedAnalytics": "Г.О. бойынша қаралған",
        "GOReviewedTotal": "Г.О. бойынша қаралған",
        "entraceSourceAnalytics": "Кіріс көздері",
        "entraceSourceTotal": "Кіріс көздері",
        "appealsAnalytics": "Тіркелген өтініштер",
        "commentEmptyMessage": "Пікірлерді көрсету үшін байланысты орнату қажет",
        "totalAppeals": "Барлық өтінімдер",
        "entranceSources": "Кіріс көздері",
        "GOReviewed": "ГО-да қаралған",
        "deadlineViolation": "Срокты бұза отырып қаралған",
        "applications": "Өтінімдер",
        "complaints": "Шағымдар",
        "suggestions": "Ұсыныстар",
        "others": "Басқа (хабарлама, сұрау, жауап)",
        "entranceSourceAnalytics": "Кіріс көздері",
        "entranceSourceTotal": "Барлығы (кіріс көздері)",
        "appealsTotal": "Барлығы (өтініштер)",
        "noReportForms": "Таңдалған ұйым үшін есеп формалары жоқ",
        "noFormF2GO": "Таңдалған ұйым үшін Ф2ГО есеп формасы жоқ",
        "projectButton": "Жобалар",
        "fundingButton": "Қаржыландыру",
        "startDateLabel": "Жобаның басталу күні",
        "endDateLabel": "Аяқталу күні",
        "totalKZ": "Қазақстан бойынша барлығы:",
        "progressText": "{name} - {value}{measure_unit}",
        "percent": "{percent} %",
        "noData": "Деректер жоқ",
        "millionTenge": "млн.тг",
        "project": "жоба",
        "projectGenitive": "жобаның",
        "projects": "жобалар",
        "ob_tg": "*Қаржыландыру көлемі, млн. тг",
        "start": "Бастау",
        "end": "Аяқталу",
        "parent_organization_placeholder": "Ата-ана ұйым",
        "no_data": "Деректер жоқ",
        "select_options_error": "Сұрау нәтижелерін алу мүмкін болмады",
        "select_options_fetch_error": "Селект опцияларын алу мүмкін болмады",
        "scroll_data_error": "Деректерді алу мүмкін болмады.",
        "parent_organization": "Ата ұйым",
        "error_no_results": "Сұрау нәтижелерін алу мүмкін болмады",
        "error_get_options": "Селект опцияларын алу мүмкін болмады",
        "error_fetching_data": "Деректерді алу мүмкін болмады.",
        "option_all": "Барлығы",
        "option_self": "Өз",
        "option_descendants": "Құрылымдық бөлімшелер",
        "legend_favorable": "оң әкімшілік акт қабылданды",
        "legend_burdensome": "құқықтық акт қабылданды",
        "legend_response": "авторға жауап берілді",
        "legend_terminated": "тоқтатылды (ст. 89; 70 АППК)",
        "legend_noted": "назарға алынды (ст. 89 АППК)",
        "legend_other": "басқа",
        "regions": {
            "abai": "Абай облысы",
            "akmola": "Ақмола облысы",
            "aktobe": "Ақтөбе облысы",
            "almaty": "Алматы облысы",
            "almaty_city": "Алматы",
            "atyrau": "Атырау облысы",
            "jambyl": "Жамбыл облысы",
            "ulytau": "Ұлытау облысы",
            "kostanay": "Қостанай облысы",
            "kyzylorda": "Қызылорда облысы",
            "mangystau": "Маңғыстау облысы",
            "north_kazakhstan": "Солтүстік Қазақстан",
            "astana": "Астана",
            "pavlodar": "Павлодар облысы",
            "shymkent_city": "Шымкент",
            "turkestan": "Түркістан облысы",
            "west_kazakhstan": "Батыс Қазақстан",
            "karaganda": "Қарағанды облысы",
            "jetisu": "Жетісу облысы",
            "east_kazakhstan": "Шығыс Қазақстан"
        }
    }
}