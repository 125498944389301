export default {
    "history": {
        "noData": "Деректер жоқ",
        "connect": "Байланыс",
        "action": "Әрекет",
        "property": "Қасиет",
        "fromOrganization": "Ұйымнан",
        "was": "Бұрын",
        "became": "Болды",
        "moreDetails": "Толығырақ",
        "date": "Күні",
        "collapse": "Қысқарту",
        "expand": "Кеңейту",
        "close": "Жабу",
        "today": "Бүгін",
        "yesterday": "Кеше"
    }
}