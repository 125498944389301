export default {
    "support": {
        "helpAndSupport": "Көмек және қолдау",
        "newsFeed": "Жаңалықтар лентасы",
        "gos24Info": "Gos24.КОННЕКТ туралы ақпарат",
        "knowledgeBase": "Білім базасы",
        "instructions": "Қызметпен жұмыс жасау нұсқаулары",
        "techSupportChat": "Техникалық қолдау чаты",
        "contactTechSupport": "Техникалық қолдаумен байланысу",
        "newsFeed": "Жаңалықтар лентасы",
        "addNews": "Жаңалық қосу",
        "noNewNews": "Жаңа жаңалықтар жоқ",
        "expand": "Ашу",
        "centerTitle": "Gos24.КОННЕКТ қолдау орталығы",
        "addSection": "Бөлім қосу",
        "addChapter": "Бөлім қосу",
        "addPage": "Бет қосу",
        "viewAllPages": "Барлық беттерді көру",
        "sections": "Бөліктер",
        "close": "Жабу",
        "pageNotFound": "Бет табылмады",
        "archive": "Архив",
        "lastUpdate": "Соңғы жаңарту",
        "editSection": "Бөлікті өңдеу",
        "editChapter": "Бөлімді өңдеу",
        "editPage": "Бетті өңдеу",
        "displayOnMain": "Негізгі бетте көрсетіледі",
        "notDisplayedOnMain": "Негізгі бетте көрсетілмейді",
        "thankYouHelped": "Рахмет, көмектесті",
        "didNotHelp": "Көмектескен жоқ",
        "askQuestion": "Сұрақ қою",
        "usefulTips": "Тағы бірнеше пайдалы кеңестер",
        "comments": "Пікірлер",
        "base_search": "Білім базасы бойынша іздеу",
        "section_select": "Бөлімді таңдау"
    }
}